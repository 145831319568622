<template>
  <div v-if="permission.read_perm === 1" class="text-center">
    <v-dialog
      v-model="dialog"
      :max-width="wWidth < 780 ? '100%' : '60%'"
      persistent
    >
      <!-- @click:outside="clickOutside" -->
      <template v-slot:activator="{ on, attrs }">
        <p
          id="docCategoryAdd"
          v-if="param.act === 'add'"
          v-bind="attrs"
          v-on="on"
          @click="add"
        ></p>
        <p
          id="docCategoryEdit"
          v-else
          v-bind="attrs"
          v-on="on"
          @click="update"
        ></p>
      </template>
      <div>
        <v-container style="background: white; border-radius: 5px">
          <v-form
            v-if="permission.create_perm == 1 || permission.update_perm == 1"
            ref="entryForm"
            @submit.prevent="submit"
            style="position: relative"
          >
            <v-card
              flat
              style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                border-bottom: 1px solid black;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div
                style="
                  display: flex;
                  jsutify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
              >
                <v-toolbar-title class="overline">
                  <p
                    v-if="wWidth > 779"
                    style="font-size: 20px; margin: 0; font-weight: bold"
                  >
                    Kategori Dokumen (Add/Edit)
                  </p>
                  <p
                    v-else
                    style="font-size: 14px; margin: 0; font-weight: bold"
                  >
                    Kategori Dokumen (Add/Edit)
                  </p>
                </v-toolbar-title>
              </div>
              <div class="d-flex justify-center align-center">
                <div v-if="wWidth > 779">
                  <v-btn
                    v-if="
                      permission.create_perm == 1 || permission.update_perm == 1
                    "
                    type="submit"
                    rounded
                    outlined
                    small
                    elevation="1"
                    color="indigo"
                    class="indigo--text"
                    style="font-size:12px;margin-right:10px;"
                  >
                    simpan
                  </v-btn>
                </div>

                <v-btn
                  :rounded="wWidth > 779"
                  :outlined="wWidth > 779"
                  small
                  :elevation="wWidth > 779 ? 1 : 0"
                  color="red"
                  class="red--text"
                  style="font-size:12px;margin-right:10px;"
                  @click="close"
                  :icon="wWidth < 780"
                >
                  <span v-if="wWidth > 779"> Batal</span>
                  <v-icon v-else color="red">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-card>

            <v-row style="padding: 15px">
              <v-col cols="12" md="2" style="padding: 0;">
                <div
                  style="padding: 0 10px;height:40px;display:flex; justify-content:center; align-items:center;"
                >
                  <v-col cols="12" style="padding: 0; ">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);
                      "
                    >
                      Nama Kategori
                    </p>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="8" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.name"
                      dense
                      outlined
                      style="margin: 0"
                      :rules="nameRules"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0;"> </v-col>

              <v-col cols="12" md="2" style="padding: 0;">
                <div
                  style="padding: 0 10px;height:40px;display:flex; justify-content:center; align-items:center;"
                >
                  <v-col cols="12" style="padding: 0; ">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);
                      "
                    >
                      Department
                    </p>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="8" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      v-model="form.department_approve_request"
                      :items="department"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      style="margin: 0"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
            </v-row>
            <!-- <div class="doc-form-btn-2"> -->
            <div v-if="wWidth < 780" style="padding: 0 10px;">
              <v-btn
                v-if="
                  permission.create_perm == 1 || permission.update_perm == 1
                "
                type="submit"
                rounded
                elevation="1"
                color="indigo"
                class="white--text"
                style="font-size:14px;width:100%;"
              >
                simpan
              </v-btn>
            </div>
            <!-- </div> -->
          </v-form>
        </v-container>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapActions } from 'vuex'
export default {
  name: 'Archive-Form',
  props: ['param', 'permission'],
  data() {
    return {
      wWidth: window.innerWidth,
      eSanqua: buildType.apiURL('esanqua'),
      upload: buildType.apiURL('upload2'),
      download: buildType.apiURL('download'),
      dialog: false,
      form: {},
      loading: false,
      nameRules: [],
      department: null
    }
  },
  computed: {
    // ...mapGetters([
    //   'getDropdownDepartment'
    // ])
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions(['dropdownDepartment']),
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clear() {
      this.nameRules = []
      this.form = {}
      this.$emit('loadPage')
    },
    clickOutside() {
      this.clear()
    },
    close() {
      this.dialog = false
      this.clear()
    },
    // loadForm() {
    //   console.log(this.param)
    // },
    departmentDropdown() {
      this.department = [{ id: '', name: 'All' }]
      this.dropdownDepartment()
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            this.department.push(res.data[i])
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    add() {
      this.departmentDropdown()
      this.form = {
        name: '',
        department_approve_request: 0
      }
    },
    update() {
      this.departmentDropdown()
      this.form = {
        name: this.param.items.name,
        department_approve_request: this.param.items.department_approve_request
      }
    },
    submit() {
      this.nameRules = [v => !!v || 'Name is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      console.log(this.form)
      const newForm = {
        name: this.form.name,
        department_approve_request: this.form.department_approve_request
      }

      if (this.param.act === 'update') {
        Object.assign(newForm, {
          act: this.param.act,
          id: this.param.items.id
        })
      } else {
        Object.assign(newForm, {
          act: this.param.act
        })
      }
      if (newForm.has_expire === 0) {
        delete newForm.doc_expiry_date
      }
      console.log(newForm)
      this.save(newForm)
    },
    save(form) {
      axios
        .post(`${this.eSanqua}master/archive/documentcategory/save`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            if (this.param.act === 'add') {
              this.close()
            } else {
              this.$emit('loadPage')
            }
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.arch-add-new-btn {
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}
</style>
